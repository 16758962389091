import * as React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import Layout from '../components/Layout'
import Grid from '@material-ui/core/Grid';


const Card = ({data, $bg}) => {
  var galleyImages = data.field_galeria_articulos.split(',');
  return (
    <Grid item xs={12} sm={6} lg={4}>
      <Link to={`/${data.fields.slug}`} class="card-link">
        <div class={'card'} style={{background: `url(${data.field_image ? data.field_image : galleyImages[0]}) center center no-repeat`}}>
          <div class='content'>
            <div class={"iconW"}>
              <img src={require('../images/logo.svg')} width={50} alt={''} />
            </div>
            <h2>{data.title}</h2>
          </div>
        </div>
      </Link>
    </Grid>
  )
}

const Page = () => {
  return (
    <Layout headerVideo={true}>
        <Grid container align={"center"} justify={'center'} id={"chapters"}>
          <StaticQuery
            query={queryChapters}
            render={data =>
              data.allRtvcChapters.nodes.map(item => <Card data={item} $bg={"tomato"} />)
            }
          />
        </Grid>
    </Layout>
  )
}

// Query Single
const queryChapters = graphql`
  query {
   allRtvcChapters {
      nodes {
        title
        field_image
        field_video_embed_url
        field_player_articulos
        field_galeria_articulos
        field_files
        body
        id
        fields {
          slug
        }
      }
    }
  }
`

export default Page